import { Button } from "@mui/material";

const ArticleCard = ({ heading, content, url }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "280px",
        borderRadius: "20px",
        backgroundColor: "#1C1D20",
        display: "flex",
        alignItems: "center",
        padding: "10px",
        justifyContent: "flex-start",
      }}
    >
      <div
        style={{
          flex: "none",
          width: "280px",
          height: "280px",
          borderRadius: "10px",
          backgroundColor: "gray",
          overflow: "hidden",
        }}
      >
        <img src="https://picsum.photos/300/300" />
      </div>
      <div
        style={{
          width: "calc(700px-400px)",
          height: "280px",
          display: "flex",
          flexDirection: "column",
          marginLeft: "20px",
        }}
      >
        <p
          style={{
            fontSize: "1.5rem",
            fontWeight: 600,
            color: "#DCE3F2",
            margin: 0,
            height: "20%",
            textAlign: "justify",
            fontFamily: '"Merriweather", serif',
          }}
        >
          {heading?.substring(0, 60)}
          {heading?.length > 60 ? "...." : ""}
        </p>
        <p
          style={{
            fontSize: "1.2rem",
            color: "#9DA3B3",
            fontFamily: '"Open Sans", sans-serif',
            lineHeight: "30px",
            height: "40%",
          }}
        >
          {content?.substring(0, 170)}
          {heading?.length > 60 ? "...." : ""}
        </p>
        <div
          style={{ width: "100%", height: "max-content", marginTop: "auto" }}
        >
          <Button variant="contained">READ</Button>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
