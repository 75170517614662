import ArticleCard from "./components/articleCard";
import Grid from "@mui/material/Grid2";

const Articles = () => {
  return (
    <div
      style={{
        width: "100vw",
        minHeight: "100vh",
        height: "max-content",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <div style={{ width: "90%", height: "90%" }}>
        <Grid container spacing={5}>
          <Grid size={6}>
            <ArticleCard
              heading={
                "Exploring the Mysteries of The Universe: A Journey Through Cosmos"
              }
              content={
                " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis"
              }
              url={""}
            />
          </Grid>
          <Grid size={6}>
            <ArticleCard
              heading={
                "Exploring the Mysteries of The Universe: A Journey Through Cosmos"
              }
              content={
                " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis"
              }
              url={""}
            />
          </Grid>
          <Grid size={8}>
            <ArticleCard
              heading={
                "Exploring the Mysteries of The Universe: A Journey Through Cosmos"
              }
              content={
                " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis"
              }
              url={""}
            />
          </Grid>
          <Grid size={6}>
            <ArticleCard
              heading={
                "Exploring the Mysteries of The Universe: A Journey Through Cosmos"
              }
              content={
                " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis"
              }
              url={""}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Articles;
