import tableImage from "./table.png";
import displayBottom from "./displayBottom.png";
import displayTop from "./displayTop.png";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Articles from "./Pages/Articles";
import TempPage from "./TempPage";
import TempPageDecoder from "./TempPageDecoder";

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/sp-anku" element={<TempPageDecoder />} />
        <Route path="/anku-sp" element={<TempPage />} />

        {/* <Route path="about" element={<About />} /> */}
        {/* <Route path="contact" element={<Contact />} /> */}
      </Routes>
    </div>
  );
};
export default App;
