import randomColor from "randomcolor";
import { useEffect, useState } from "react";
function countdownUntilDate(targetDate) {
  // Get the current date and time
  const currentDate = new Date();

  // Convert targetDate to a Date object if it's a string
  if (typeof targetDate === "string") {
    targetDate = new Date(targetDate);
  }

  // Calculate the difference in milliseconds
  const millisecondsDifference = targetDate - currentDate;

  // Calculate days, hours, minutes, and seconds
  const days = Math.floor(millisecondsDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (millisecondsDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor(
    (millisecondsDifference % (1000 * 60 * 60)) / (1000 * 60)
  );
  const seconds = Math.floor((millisecondsDifference % (1000 * 60)) / 1000);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}

const END_TIME = "2024-11-08 13:07:24";
const outputSequence = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];
const inputSequence = [
  "n",
  "m",
  "r",
  "p",
  "f",
  "i",
  "g",
  "o",
  "k",
  "e",
  "y",
  "s",
  "h",
  "c",
  "u",
  "b",
  "q",
  "d",
  "v",
  "a",
  "t",
  "j",
  "w",
  "l",
  "x",
  "z",
];

const TempPageDecoder = () => {
  const [inputChar, setInputChar] = useState("");
  const [outputChar, setOutputChar] = useState("");

  //   const shifter = () => {
  //     setOutputChar(outputSequence[inputSequence.findIndex(inputChar)]);
  //   };

  console.log(countdownUntilDate(END_TIME));
  const [duration, setDuration] = useState(countdownUntilDate(END_TIME));
  const [textColor, setTextColor] = useState(randomColor());
  const [gradient1, setGradient1] = useState(
    randomColor({
      luminosity: "dark",
      hue: "random",
    })
  );

  useEffect(() => {
    console.log(inputChar);
    console.log(inputSequence.indexOf(inputChar));
    const a = inputSequence.indexOf(inputChar);
    if (a === -1) return setOutputChar("");
    setOutputChar(outputSequence[inputSequence.indexOf(inputChar)]);
    setTextColor(randomColor());
    setGradient1(
      randomColor({
        luminosity: "light",
        hue: "random",
      })
    );
  }, [inputChar]);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background: "black",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",

        // background:
        //   "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(14,14,122,1) 35%, rgba(0,212,255,1) 100%)",
      }}
    >
      {/* <p
        style={{
          fontSize: "5vw",
          margin: 0,
          color: "white",
          top: "100px",
        }}
      >
        Oii
      </p> */}
      <p
        style={{
          width: "40vw",
          height: "10vh",
          color: textColor,
          fontSize: "20vh",
          textAlign: "center",

          textShadow: `3px 23px 95px ${textColor},
             7px -14px 80px ${gradient1}`,
        }}
      >
        {outputChar}
      </p>
      <input
        value={inputChar}
        style={{
          width: "40vw",
          height: "10vh",
          fontSize: "10vh",
          borderRadius: "20px",
          border: "0px",
          color: "white",
          backgroundColor: "rgba(100,100,100,0.7)",
          textAlign: "center",
          marginBottom: "20vh",
        }}
        onChange={(event) => {
          console.log("event.target.value", event.target.value);
          setInputChar(event.target?.value[0]?.toLowerCase() || "");
        }}
      />
    </div>
  );
};

export default TempPageDecoder;
