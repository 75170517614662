import randomColor from "randomcolor";
import { useEffect, useState } from "react";
function countdownUntilDate(targetDate) {
  // Get the current date and time
  const currentDate = new Date();

  // Convert targetDate to a Date object if it's a string
  if (typeof targetDate === "string") {
    targetDate = new Date(targetDate);
  }

  // Calculate the difference in milliseconds
  const millisecondsDifference = targetDate - currentDate;

  // Calculate days, hours, minutes, and seconds
  const days = Math.floor(millisecondsDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (millisecondsDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor(
    (millisecondsDifference % (1000 * 60 * 60)) / (1000 * 60)
  );
  const seconds = Math.floor((millisecondsDifference % (1000 * 60)) / 1000);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}

const END_TIME = "2024-11-08 13:07:24";
const TempPage = () => {
  const [color, setColor] = useState(randomColor());
  const [color2, setColor2] = useState(randomColor());
  const [color3, setColor3] = useState(randomColor());
  const [color4, setColor4] = useState(randomColor());
  const [color5, setColor5] = useState(randomColor());
  const [color6, setColor6] = useState(randomColor());
  console.log(countdownUntilDate(END_TIME));
  const [duration, setDuration] = useState(countdownUntilDate(END_TIME));

  console.log(new Date());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setDuration(countdownUntilDate(END_TIME));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (duration.seconds < 0) {
      setColor("#ffffff");
      setColor2("#ffffff");
    }
  }, [duration]);
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background: color,
        position: "relative",
        // background:
        //   "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(14,14,122,1) 35%, rgba(0,212,255,1) 100%)",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 6,
          width: "100vw",
          height: "100vh",
        }}
      >
        <div
          style={{
            width: "100vw",
            height: "50vh",
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: duration.seconds > 0 ? "2rem" : "30vh",
              fontWeight: 900,
              color: duration.seconds > 0 ? "rgba(200,200,200,0.2)" : "#ED254E",
              margin: 0,
            }}
          >
            AR
          </p>
        </div>
        <div
          style={{
            width: "100vw",
            height: "50vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: duration.seconds > 0 ? "2rem" : "30vh",
              fontWeight: 900,
              color: duration.seconds > 0 ? "rgba(200,200,200,0.2)" : "#ED254E",
              margin: 0,
            }}
          >
            SP
          </p>
        </div>
      </div>
      {duration.seconds > 0 ? (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            position: "absolute",
            zIndex: 2,
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p style={{ fontSize: "4rem", color: "white", fontWeight: 800 }}>
            {duration.days < 0 ? 0 : duration.days}{" "}
            <span style={{ color: "lightgray" }}>Days</span>
          </p>
          <p style={{ fontSize: "4rem", color: "white", fontWeight: 800 }}>
            {duration.hours < 0 ? 0 : duration.hours}{" "}
            <span style={{ color: "lightgray" }}>Hours</span>
          </p>
          <p style={{ fontSize: "4rem", color: "white", fontWeight: 800 }}>
            {duration.minutes < 0 ? 0 : duration.minutes}{" "}
            <span style={{ color: "lightgray" }}>Minutes</span>
          </p>
          <p style={{ fontSize: "4rem", color: "white", fontWeight: 800 }}>
            {duration.seconds < 0 ? 0 : duration.seconds}{" "}
            <span style={{ color: "lightgray" }}>Sec</span>
          </p>
        </div>
      ) : null}
      <div style={{ width: "100vw", height: "50vh", background: color }}></div>
      <div style={{ width: "100vw", height: "50vh", background: color2 }}></div>
    </div>
  );
};

export default TempPage;
